import React, {
  FC, useContext, useState, useMemo,
} from "react";
import { PUBLIC_API_GRAPHQL_ENDPOINT } from "../apollo";

export interface ILoggedInStatusProps {
    children: React.ReactNode;
}

export enum ILoggedInStatus {
    UNKNOWN = "UNKNOWN",
    LOADING = "LOADING",
    NOT_LOGGED_IN = "NOT_LOGGED_IN",
    LOGGED_IN = "LOGGED_IN",
}
export const LoggedInStatusContext = React.createContext({
  status: ILoggedInStatus.UNKNOWN,
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  loadStatus: async () => ILoggedInStatus.UNKNOWN,
  contactId: "",
});

const OK_STATUS_CODE = 200;
export const useGetLoggedInStatus = () => useContext(LoggedInStatusContext).status;
export const useLoadLoggedInStatus = () => useContext(LoggedInStatusContext).loadStatus;
export const useContactId = () => useContext(LoggedInStatusContext).contactId;

export const LoggedInStatus: FC<ILoggedInStatusProps> = ({ children }) => {
  const [loggedInStatus, setLoggedInStatus] = useState(ILoggedInStatus.UNKNOWN);
  const [contactId, setContactId] = useState("");
  const loadStatus = async () => {
    setLoggedInStatus(ILoggedInStatus.LOADING);

    const result = await fetch(PUBLIC_API_GRAPHQL_ENDPOINT, {
      body: JSON.stringify({ query: "{\n  userId\n  }\n" }),
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    // for dev use only
    // const result = { status: 200, json: () => ({ data: { userId: "123" } }) };

    const isResponseOk = result.status === OK_STATUS_CODE;
    const newStatus = isResponseOk ? ILoggedInStatus.LOGGED_IN : ILoggedInStatus.NOT_LOGGED_IN;
    setLoggedInStatus(newStatus);
    if (isResponseOk) {
      setContactId((await result.json()).data.userId);
    }
    return newStatus;
  };

  const memoizedStatus = useMemo(
    () =>
      /* so it won't get reloaded */
      (
        {
          status: loggedInStatus,
          loadStatus,
          contactId,
        }
      ),
    [loadStatus, loggedInStatus, contactId],
  );

  return (
    <LoggedInStatusContext.Provider value={memoizedStatus}>
      {children}
    </LoggedInStatusContext.Provider>
  );
};
